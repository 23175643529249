@import url(https://fonts.googleapis.com/css?family=Mansalva&display=swap);
.fc5 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.fc1 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Courier New";
    font-style: normal
}

.fc2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.fc6 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.fc10 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.fc3 {
    padding: 40px;
    margin-bottom: 10%;
}

.fc12 {
    padding: 0;
    margin: 0
}

.fc8 {
    margin-left: 36pt;
    padding-left: 0pt
}

.fc11 {
    font-weight: 400;
    font-family: "Courier New"
}

.fc0 {
    font-weight: 700
}

.fc4 {
    height: 11pt
}

li {
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    font-size: 11pt;
    font-family: "Arial"
}
.cc1 {
    margin-left: 72pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.cc3 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.cc4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.cc0 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.cc5 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: italic
}

.cc7 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.cc8 {
    padding: 40px;
    margin-bottom: 10%;
}

.cc2 {
    padding: 0;
    margin: 0
}

.cc6 {
    font-weight: 700
}
.ac2 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.ac0 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.ac1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.ac5 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.ac4 {
    padding: 40px;
    margin-bottom: 10%;
}

.ac6 {
    padding: 0;
    margin: 0
}

.ac3 {
    font-weight: 700
}
.csc0 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.csc4 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.csc3 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.csc1 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.csc7 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.csc5 {
    padding: 40px;
    margin-bottom: 10%;
}

.csc6 {
    padding: 0;
    margin: 0
}

.csc2 {
    font-weight: 400;
    font-family: "Courier New"
}
.tsc1 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.tsc0 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.tsc2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.tsc7 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.tsc3 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.tsc5 {
    padding: 40px;
    margin-bottom: 10%;
}

.tsc6 {
    font-weight: 400;
    font-family: "Courier New"
}

.tsc4 {
    padding: 0;
    margin: 0
}
.bc0 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.bc3 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.bc1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.bc5 {
    padding: 40px;
    margin-bottom: 10%;
}

.bc4 {
    padding: 0;
    margin: 0
}

.bc2 {
    margin-left: 36pt;
    padding-left: 0pt
}

.bc6 {
    font-weight: 400;
    font-family: "Courier New"
}

.bc7 {
    height: 11pt
}
.kc2 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.kc3 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.kc7 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.kc0 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.kc4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.kc1 {
    padding: 40px;
    margin-bottom: 10%;
}

.kc5 {
    font-weight: 400;
    font-family: "Courier New"
}

.kc6 {
    padding: 0;
    margin: 0
}
.App {
  margin: 50px;
  text-align: left;
}

.Manage {
  margin-left: 20%;
  margin-right: 20%;
}

.Manage .darkButton {
  margin-left: 20px;
}

#login {
  text-align: center;
  margin-top: 50px;
}

#loading {
  text-align: center;
  margin-top: 30px;
  font-weight: bold;
  color: rgb(255, 153, 102);
}

#write {
  text-align: center;
  margin-top: 30px;
}

.Modal {
  position: absolute;
  top: 40%;
  left: 30%;
  right: 30%;
  bottom: 40%;
  padding: 30px;
  background-color: white;
}

.Modal .darkButton {
  float: left;
  margin-left: 0;
}

.Form {
  margin-top: 20px;
  margin-right: 45%;
  height: 100%;
  background-color:white;
  position: fixed;
  /*z-index: 1;*/
  top: 0;
  overflow-y: scroll;
  /*padding-top: 20px;*/
  /*left:0;
  padding:50px;*/
  /*padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
}

#back {
  float: right;
  margin-top: 40px;
}

.Doc {
  margin-left: 60%;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-y: scroll;
  /*padding-top: 20px;*/
  right:0;
  background-color: rgb(20,20,20);
  /*padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
  padding:50px;
  color: white;
}

.Doc img {
  width: 100%;
}

.Ref {
  clear: left;
}

.header {
  font-family: 'Mansalva', 'cursive';
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.WorkCard {
  margin: 20px;
  padding: 30px;
  padding-bottom: 60px;
  background-color: rgb(255, 153, 102);
  border: hidden;
  border-radius: 5px;
  box-shadow: 5px 5px black;
}

.lightButton {
  float: left;
  margin: 10px;
  margin-left: 0px;
  font-weight: bold;
  color:white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.lightButton:hover{
  color: rgb(255, 153, 102);
}

.darkButton {
  margin: 10px;
  font-weight: bold;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.darkButton:hover {
  color: rgb(255, 153, 102);
}

#play {
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 30px;
  text-align: center;
}

.Manage a {
  color: black;
  font-weight: bold;
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.Manage a:hover, a:active {
  color: rgb(255, 153, 102);
}

.Form a {
  color: rgb(255, 153, 102);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
}

.Form a:hover {
  color: black;
}

.WorkCard .darkButton {
  float: left;
  margin-left: 0px;
}

.WorkCard .darkButton:hover {
  color: white;
}

/*input[type=text] {
  border: 3px solid;
  border-radius: 5px;
  padding: 10px;
  margin: 20px;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
}*/

.TextForm {
  margin: 12px;
}

textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 14px;
  background: rgb(240,240,240);
  border: hidden;
  border-radius: 5px;
  font-size: 14px;
  font-family: Tahoma, sans-serif;
  resize: vertical;
  color: gray;
}

.solidButton {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
  margin-right: 10px;
  float: left;
  font-size: 13px;
  font-weight: bold;
  background-color: gray;
  color: white;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  -webkit-transition-duration: .3s;
          transition-duration: .3s;
  border: hidden;
  border-radius: 5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif
}

.solidButton:hover {
  /* TODO: kinda hella ugly */
  background-color:rgb(255, 153, 102);
}

/*input[type=submit] {
  font-size: 13px;
  font-weight: 700;
  background-color: gray;
  color: white;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  transition-duration: .3s;
  border: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif
}
input[type=submit]:hover {
  background-color: rgb(150,150,150);
}*/

/*label {
  font-size: 14px;
  margin: 12px;
  display: inline-block;
}*/

p {
  font-size: 14px;
  line-height: 20px;
}

.Form p {
  margin: 12px;
  display: inline-block;
}

h1{
  border-bottom: solid rgb(230,230,230) 2px;
  margin: 12px;
  padding: 12px 0px;
}

h2{
  border-left: solid rgb(230,230,230) 3px;
  margin: 12px;
  padding: 0px 12px;
}

#form {
  margin: 10%;
}

#text {
  border: 3px solid #cccccc;
  padding: 5px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

