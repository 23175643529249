.bc0 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.bc3 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.bc1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.bc5 {
    padding: 40px;
    margin-bottom: 10%;
}

.bc4 {
    padding: 0;
    margin: 0
}

.bc2 {
    margin-left: 36pt;
    padding-left: 0pt
}

.bc6 {
    font-weight: 400;
    font-family: "Courier New"
}

.bc7 {
    height: 11pt
}