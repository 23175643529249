@import url('https://fonts.googleapis.com/css?family=Mansalva&display=swap');

.App {
  margin: 50px;
  text-align: left;
}

.Manage {
  margin-left: 20%;
  margin-right: 20%;
}

.Manage .darkButton {
  margin-left: 20px;
}

#login {
  text-align: center;
  margin-top: 50px;
}

#loading {
  text-align: center;
  margin-top: 30px;
  font-weight: bold;
  color: rgb(255, 153, 102);
}

#write {
  text-align: center;
  margin-top: 30px;
}

.Modal {
  position: absolute;
  top: 40%;
  left: 30%;
  right: 30%;
  bottom: 40%;
  padding: 30px;
  background-color: white;
}

.Modal .darkButton {
  float: left;
  margin-left: 0;
}

.Form {
  margin-top: 20px;
  margin-right: 45%;
  height: 100%;
  background-color:white;
  position: fixed;
  /*z-index: 1;*/
  top: 0;
  overflow-y: scroll;
  /*padding-top: 20px;*/
  /*left:0;
  padding:50px;*/
  /*padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
}

#back {
  float: right;
  margin-top: 40px;
}

.Doc {
  margin-left: 60%;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-y: scroll;
  /*padding-top: 20px;*/
  right:0;
  background-color: rgb(20,20,20);
  /*padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
  padding:50px;
  color: white;
}

.Doc img {
  width: 100%;
}

.Ref {
  clear: left;
}

.header {
  font-family: 'Mansalva', 'cursive';
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.WorkCard {
  margin: 20px;
  padding: 30px;
  padding-bottom: 60px;
  background-color: rgb(255, 153, 102);
  border: hidden;
  border-radius: 5px;
  box-shadow: 5px 5px black;
}

.lightButton {
  float: left;
  margin: 10px;
  margin-left: 0px;
  font-weight: bold;
  color:white;
  transition: 0.3s;
}

.lightButton:hover{
  color: rgb(255, 153, 102);
}

.darkButton {
  margin: 10px;
  font-weight: bold;
  transition: 0.3s;
}

.darkButton:hover {
  color: rgb(255, 153, 102);
}

#play {
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 30px;
  text-align: center;
}

.Manage a {
  color: black;
  font-weight: bold;
  text-decoration: none;
  transition: 0.3s;
}

.Manage a:hover, a:active {
  color: rgb(255, 153, 102);
}

.Form a {
  color: rgb(255, 153, 102);
  transition: 0.3s;
  text-decoration: none;
}

.Form a:hover {
  color: black;
}

.WorkCard .darkButton {
  float: left;
  margin-left: 0px;
}

.WorkCard .darkButton:hover {
  color: white;
}

/*input[type=text] {
  border: 3px solid;
  border-radius: 5px;
  padding: 10px;
  margin: 20px;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
}*/

.TextForm {
  margin: 12px;
}

textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  width: 100%;
  padding: 14px;
  background: rgb(240,240,240);
  border: hidden;
  border-radius: 5px;
  font-size: 14px;
  font-family: Tahoma, sans-serif;
  resize: vertical;
  color: gray;
}

.solidButton {
  width: fit-content;
  margin-top: 10px;
  margin-right: 10px;
  float: left;
  font-size: 13px;
  font-weight: bold;
  background-color: gray;
  color: white;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  transition-duration: .3s;
  border: hidden;
  border-radius: 5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif
}

.solidButton:hover {
  /* TODO: kinda hella ugly */
  background-color:rgb(255, 153, 102);
}

/*input[type=submit] {
  font-size: 13px;
  font-weight: 700;
  background-color: gray;
  color: white;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  transition-duration: .3s;
  border: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif
}
input[type=submit]:hover {
  background-color: rgb(150,150,150);
}*/

/*label {
  font-size: 14px;
  margin: 12px;
  display: inline-block;
}*/

p {
  font-size: 14px;
  line-height: 20px;
}

.Form p {
  margin: 12px;
  display: inline-block;
}

h1{
  border-bottom: solid rgb(230,230,230) 2px;
  margin: 12px;
  padding: 12px 0px;
}

h2{
  border-left: solid rgb(230,230,230) 3px;
  margin: 12px;
  padding: 0px 12px;
}

#form {
  margin: 10%;
}

#text {
  border: 3px solid #cccccc;
  padding: 5px;
}
