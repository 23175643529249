.fc5 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.fc1 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Courier New";
    font-style: normal
}

.fc2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.fc6 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.fc10 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.fc3 {
    padding: 40px;
    margin-bottom: 10%;
}

.fc12 {
    padding: 0;
    margin: 0
}

.fc8 {
    margin-left: 36pt;
    padding-left: 0pt
}

.fc11 {
    font-weight: 400;
    font-family: "Courier New"
}

.fc0 {
    font-weight: 700
}

.fc4 {
    height: 11pt
}

li {
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    font-size: 11pt;
    font-family: "Arial"
}